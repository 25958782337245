import axios from "@/request/http";

// 检测任务管理列表
export function postSubmitUserInfo(data) {
  return axios.post("/api/service/activity/entry", data);
}

// 微信支付换取数据
export function postWxCode(data) {
  return axios.post("/api/service/activity/pay", data);
}

// 微信支付结果提交
export function postWxPayStatus(data) {
  return axios.post("/api/service/activity/payStatus", data);
}

// code换取openid
export function postCodeOpenid(data) {
  return axios.post("/api/service/activity/userInfo", data);
}
