// @ is an alias to /src
import { postSubmitUserInfo, postWxPayStatus, postCodeOpenid } from "@/api/carActivity";
import wx from "weixin-js-sdk";
import { Toast } from "vant";
const sessionStorage = window.sessionStorage;
export default {
  name: "HomeView",
  components: {},
  // 模版渲染前
  created() {
    if (sessionStorage.getItem("userData")) {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      this.customer_name = userData.name;
      this.customer_phone = userData.phone;
    }
    this.isSuccess = JSON.parse(sessionStorage.getItem("isSuccess"));
    // sessionStorage.setItem("isSuccess", false);
    // var u = navigator.userAgent;
    // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // if (!this.getUrlParam("code")) {
    //   // if (isAndroid) {
    //   //   //android终端
    //   //   window.open(
    //   //     `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx468b768ca4a292f7&redirect_uri=https://activity.kungfuautoservice.com?id=${this.$route.query.id}&response_type=code&scope=snsapi_userinfo&state=type`
    //   //   );
    //   // }
    //   // if (isiOS) {
    //   //   //ios终端
    //   //   window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx468b768ca4a292f7&redirect_uri=https://activity.kungfuautoservice.com?id=${this.$route.query.id}&response_type=code&scope=snsapi_userinfo&state=type`;
    //   // }
    //   // window.open(
    //   //   `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx468b768ca4a292f7&redirect_uri=https://activity.kungfuautoservice.com?id=${this.$route.query.id}&response_type=code&scope=snsapi_userinfo&state=type`
    //   // );
    // }
    if (!!this.getUrlParam("code") && !sessionStorage.getItem("openid")) {
      postCodeOpenid({
        code: this.getUrlParam("code")
      }).then(res => {
        console.log(res.data.body.id);
        sessionStorage.setItem("openid", res.data.body.id);
        this.onSubmit(true);
      });
    }
  },
  // 模版渲染后
  mounted() {
    if (!this.getUrlParam("code")) {
      postCodeOpenid({
        url: `https://activity.kungfuautoservice.com/?id=${this.$route.query.id}`
      }).then(res => {
        // eslint-disable-next-line no-undef
        wx.config(res.data.body.config);
        this.shareH5Fn();
      });
    }
    if (sessionStorage.getItem("isSuccess") != "") {
      this.$refs.signUp.scrollIntoView({
        behavior: "smooth",
        // 平滑过渡
        block: "center" // 上边框与视窗顶部平齐。默认值
      });
    }

    window.addEventListener("scroll", function () {
      //页面被卷去的高度: window.scrollY
      //页面被卷去的高度: window.pageYOffset
      //页面被卷去的高度: document.documentElement.scrollTop
      // console.log("页面被卷去的高度:",window.scrollY,window.pageYOffset,document.documentElement.scrollTop);
      // body页面的滚动条高度: document.body.scrollHeight
      // 整个页面你的滚动条高度: document.documentElement.scrollHeight
      // console.log(document.body.scrollHeight,document.documentElement.scrollHeight);

      // 可视页面的高度: document.documentElement.clientHeight
      // console.log(document.documentElement.clientHeight);

      if (document.documentElement.clientHeight + window.scrollY >= document.documentElement.scrollHeight) {
        console.log("触底了!!!!sss");
      }
    });
  },
  // 数据
  data() {
    return {
      customer_name: "",
      customer_phone: "",
      customer_type: 1,
      isName: false,
      isPhone: false,
      isSuccess: "",
      openid: "",
      isPhoneMsg: "请输入手机号"
    };
  },
  // 函数方法
  methods: {
    // 数据提交
    onSubmit(type) {
      let _this = this;
      if (!this.customer_name) {
        this.isName = true;
        return;
      }
      if (!this.customer_phone) {
        this.isPhone = true;
        return;
      }
      let userData = {
        name: this.customer_name,
        phone: this.customer_phone
      };
      sessionStorage.setItem("userData", JSON.stringify(userData));
      if (type) {
        this.customer_type = 2;
        if (!sessionStorage.getItem("openid")) {
          window.open(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx468b768ca4a292f7&redirect_uri=https://activity.kungfuautoservice.com?id=${this.$route.query.id}&response_type=code&scope=snsapi_userinfo&state=type`);
          return;
        }
        if (!!this.getUrlParam("code") && !sessionStorage.getItem("openid")) {
          postCodeOpenid({
            code: this.getUrlParam("code")
          }).then(res => {
            console.log(res.data.body.id);
            sessionStorage.setItem("openid", res.data.body.id);
          });
        }
        postSubmitUserInfo({
          id: sessionStorage.getItem("openid"),
          dealer_id: this.$route.query.id,
          customer_name: this.customer_name,
          customer_phone: this.customer_phone,
          customer_type: this.customer_type
        }).then(res => {
          console.log(res.data);
          let data = res.data.body;
          if (data.codeStatus == 1) {
            sessionStorage.setItem("openid", "");
            window.open(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx468b768ca4a292f7&redirect_uri=https://activity.kungfuautoservice.com?id=${this.$route.query.id}&response_type=code&scope=snsapi_userinfo&state=type`);
            return;
          }
          if (data.codeStatus == 2) {
            Toast("您已经报名成功！");
            return;
          }
          wx.config(data.config);
          // eslint-disable-next-line no-undef
          wx.chooseWXPay({
            appId: data.pay.appId,
            timestamp: data.pay.timeStamp,
            // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: data.pay.nonceStr,
            // 支付签名随机串，不长于 32 位
            package: data.pay.package,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.pay.signType,
            // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: data.pay.paySign,
            // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              let loading;
              console.log("支付结果:" + JSON.stringify(res));
              let result;
              if (res.errMsg === "chooseWXPay:ok") {
                console.log("支付成功");
                loading = Toast.loading({
                  message: "支付中...",
                  forbidClick: true //禁止点击背景关闭
                });

                result = 1;
                postWxPayStatus({
                  phone: _this.customer_phone,
                  result
                }).then(sta => {
                  loading.clear();
                  if (result == 1) {
                    Toast("支付成功！");
                    sessionStorage.setItem("isSuccess", JSON.stringify(true));
                    _this.isSuccess = true;
                    window.open(`https://activity.kungfuautoservice.com/?id=${_this.$route.query.id}`);
                  }
                  console.log(sta);
                });
              } else {
                result = 0;
                postWxPayStatus({
                  phone: _this.customer_phone,
                  result
                }).then(sta => {
                  loading.clear();
                  if (result == 1) {
                    Toast("支付成功！");
                    sessionStorage.setItem("isSuccess", JSON.stringify(true));
                    _this.isSuccess = true;
                    window.open(`https://activity.kungfuautoservice.com/?id=${_this.$route.query.id}`);
                  }
                  console.log(sta);
                });
              }
            },
            cancel: function (res) {
              console.log(res);
              Toast("已取消支付！");
              sessionStorage.setItem("isSuccess", JSON.stringify(false));
              this.isSuccess = false;
              window.open(`https://activity.kungfuautoservice.com/?id=${_this.$route.query.id}`);
            }
          });
          // eslint-disable-next-line no-undef
          wx.error(function (res) {
            console.log(res);
            sessionStorage.setItem("isSuccess", JSON.stringify(false));
            // window.open(
            //   `https://activity.kungfuautoservice.com/?id=${_this.$route.query.id}`
            // );
          });
        });
      } else {
        this.customer_type = 1;
        this.postSubmit();
      }
    },
    // 分享函数
    shareH5Fn() {
      let _this = this;
      wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        let shareData = {
          title: "好车市集",
          // 分享标题
          desc: "乐玩好车市集，年度二手车焕新，更有8重好礼等你来拿",
          // 分享描述
          link: `https://activity.kungfuautoservice.com/?id=${_this.$route.query.id}`,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: "https://activity.kungfuautoservice.com/h5_icon.jpeg",
          // 分享图标,
          success: function () {
            // 设置成功
          }
        };
        wx.updateAppMessageShareData(shareData);
        wx.updateTimelineShareData(shareData);
      });
    },
    // 报名提交请求
    postSubmit() {
      postSubmitUserInfo({
        customer_name: this.customer_name,
        customer_phone: this.customer_phone,
        customer_type: this.customer_type,
        dealer_id: this.$route.query.id
      }).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          this.isSuccess = true;
        }
      });
    },
    // 姓名输入change
    nameInput(val) {
      if (!val) {
        this.isName = true;
      } else {
        this.isName = false;
      }
    },
    // 手机号输入change
    phoneInput(val) {
      if (!val) {
        this.isPhoneMsg = "请输入手机号";
        this.isPhone = true;
      } else {
        if (val.length != 11) {
          this.isPhone = true;
          this.isPhoneMsg = "请输入正确的手机号";
        } else {
          this.isPhone = false;
        }
      }
    },
    //获取url的参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) {
        return unescape(r[2]);
      } else {
        return null; //返回参数值
      }
    }
  }
};