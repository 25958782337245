import axios from "axios";
import { Toast } from "vant";

// eslint-disable-next-line no-unused-vars
var loading;

// 请求响应状态，不再2xx开头，错误处理
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case "404":
      Toast("404 NotFound！");
      break;
    case "500":
      Toast("500 请联系后台管理员！");
      break;
    case "401":
      Toast("token 失效！");
      break;
    default:
      console.log(other);
      break;
  }
};

// 创建axios实例，设置请求超时时间
var instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 12 * 1000,
});

// 设置post请求头
// instance.defaults.headers.post["content-type"] =
//   "applocation/x-www-form-urlencoded";

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    //接口请求前做的事情
    //1、请求loading加载
    // eslint-disable-next-line no-undef
    loading = Toast.loading({
      message: "加载中...",
      forbidClick: true, //禁止点击背景关闭
    });
    // 请求前在headers中添加token
    // const token = "";
    // 请求前，将token放入请求头
    // config.headers.common.token = token;
    return config;
  },
  (error) => Promise.error(error)
);

/**
 * 接口请求后，接口响应拦截器
 */
instance.interceptors.response.use(
  //请求成功
  (res) => {
    //接口响应后，关闭loading
    loading.clear();
    console.log(res.data);
    if (res.data.code == 0) {
      // 返回请求res值，调用接口用then接收
      return Promise.resolve(res);
    } else {
      Toast(res.data.msg);
      // return Promise.reject(res);
    }
  },
  (error) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但不在2xx的范围内 --响应错误处理---错误状态码处理
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      // 请求未响应
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        // 断网处理
        // store.commit('changeNetwork', false)
        console.log("网络断开");
      } else {
        return Promise.reject(error);
      }
    }
  }
);

// eslint-disable-next-line no-undef
export default instance;
