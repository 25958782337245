import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "好车市集",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
// });
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 配置路由全局前置守卫---在每次路由跳转前都会触发
router.beforeEach((to, form, next) => {
  // 在浏览器地址栏渲染上每一个路由的名字
  document.title = to.meta.title;
  next(); // 跳转到下一个路由
});

export default router;
