import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import units from "@/utils/index";

const app = createApp(App);
app.config.globalProperties.$units = units;
import { Toast } from "vant";
import "vant/es/toast/style";

// Dialog
import { Dialog } from "vant";
import "vant/es/dialog/style";

// Notify
import { Notify } from "vant";
import "vant/es/notify/style";

// ImagePreview
import { ImagePreview } from "vant";
import "vant/es/image-preview/style";

createApp(App)
  .use(Toast)
  .use(Dialog)
  .use(Notify)
  .use(ImagePreview)
  .use(store)
  .use(router)
  .mount("#app");
