const sessionStorage = window.sessionStorage;
// 获取 token
const getToken = function () {
  return getSession("token");
};
// 缓存取 token
const setToken = function (token) {
  return setSession("token", token);
};
// 删除 token
const removeToken = function () {
  return removeSession("token");
};

// 获取 sessionstorage 信息
const getSession = function (key) {
  const str = sessionStorage.getItem(key);
  try {
    if (str.indexOf("{") === -1 && str.indexOf("[") === -1) {
      return str;
    } else {
      return JSON.parse(str);
    }
  } catch {
    return str;
  }
};
// 缓存取 sessionstorage 信息
const setSession = function (key, value) {
  let temp;
  try {
    if (typeof value === "object") {
      temp = JSON.stringify(value);
    } else {
      temp = value;
    }
    return sessionStorage.setItem(key, temp);
  } catch {
    return sessionStorage.setItem(key, "");
  }
};
// 删除 sessionstorage
const removeSession = function (key) {
  return sessionStorage.removeItem(key);
};
// 删除 所有sessionstorage
const clearSession = function () {
  return sessionStorage.clear();
};

export default {
  getToken,
  setToken,
  removeToken,
  getSession,
  setSession,
  removeSession,
  clearSession,
};
